const Util = require("./util.js");

class CloudConvert {

	constructor() {
		this.data=[];
	}
	getRedirectUrl(from, to)
	{
		return `https://cloudconvert.com/${from}-to-${to}`;
	}
	async loadConfiguration()
	{
		try
		{
			let d = await Util.getPromise("/config.json");
			this.data=d;
		} catch(e)
		{
			console.log(e);
		}
	}
	getData()
	{
		return this.data.reduce(function(result, item) {
			result[item.format] = item;
			return result;
		  }, {});
	}
	getImageList(){
		return new Set(["3fr", "arw", "bmp", "cr2", "crw", "dcr", "dng", "eps", "erf", "gif", "heic", "icns",
		"ico", "jpeg", "jpg", "mos", "mrw", "nef", "odd", "orf", "pdf", "pef", "png", "ppm", "ps",
		"psd", "raf", "raw", "svg", "svgz", "tif", "tiff", "webp", "x3f", "xcf", "xps"]);
	}
	getAudioList(){
		return new Set(["aac", "ac3", "aif", "aifc", "aiff", "amr", "au", "caf", "flac", "m4a", "m4b", "mp3",
		"oga", "ogg", "sf2", "sfark", "voc", "wav", "weba", "wma"]);
	}
	getVideoList(){
		return new Set(["3g2", "3gp", "3gpp", "avi", "cavs", "dv", "dvr", "flv", "gif", "m2ts", "m4v", "mkv",
		"mod", "mov", "mp4", "mpeg", "mpg", "mts", "mxf", "ogg", "rm", "rmvb", "swf", "ts", "vob", "webm", "wmv", "wtv"]);
	}
}

var cloudconvert = new CloudConvert();
module.exports = cloudconvert;