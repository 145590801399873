import {arrow} from '@tightrope/newtab';
const Util = require("./util.js");
const TOP_DOMAIN = Util.TOP_DOMAIN;
const Shortcuts = require("./Shortcuts.js");
const CloudConvert = require ("./cloudconvert.js");
let cloudConvertData=[];

$(document).ready(async function() {
    var searchElement = 'search-text';
    if (searchElement && searchElement !== '') window._tr_ac_se = searchElement;
    var userGuid = await Util.getGuid();
    arrow("newtab");
    if (new URL(window.location.href).searchParams.get("tutorial") === "true") {
        window.scrollTo(0, 450);
        $(".firstrun").css("display", "block");
        $("body").css({"overflow":"hidden"});
        $( ".step-a" ).click(function() {
            $( ".step-a" ).fadeOut();
            $( ".step-b" ).show();
            $( ".b-card" ).slideDown();
            $("body").css({"overflow":"scroll"});
        });

        $( ".step-b" ).click(function() {
            $( ".b-card" ).slideUp();
            $( ".firstrun" ).hide();
        });
    }
    let con=decodeURIComponent(Util.getSetting("con"));
    let to="";
    let from="";
    let type="";
    if (con)
    {
        try {
            con=JSON.parse(con);
            if (con.to)
                to=con.to;
            if (con.from)
                from=con.from;
            if (con.type)
                type=con.type;
        } catch(e)
        {
            console.log(e);
        }
    }
    await CloudConvert.loadConfiguration();
    cloudConvertData=CloudConvert.getData();
    let imageFormats=CloudConvert.getImageList();
    let audioFormats=CloudConvert.getAudioList();
    let videoFormats=CloudConvert.getVideoList();
    $(".imgCon").click(function(){
        $(".panels__item").removeClass("active");
        $(".tabs__item").removeClass("active");
        $(".imagePanel").addClass("active");
        $(this).addClass("active");
    });
    $(".audioCon").click(function(){
        $(".panels__item").removeClass("active");
        $(".tabs__item").removeClass("active");
        $(".audioPanel").addClass("active");
        $(this).addClass("active");
    });
    $(".videoCon").click(function(){
        $(".panels__item").removeClass("active");
        $(".tabs__item").removeClass("active");
        $(".videoPanel").addClass("active");
        $(this).addClass("active");
    });
    switch (type){
        case "image":
            $(".imgCon").click();
        break;
        case "audio":
            $(".audioCon").click();
        break;
        case "video":
            $(".videoCon").click();
        break;
    }
    $("#fromImage").append(`<option value="">Select file format</option>`);
    imageFormats.forEach(function (item){
        let found=cloudConvertData[item];
        if (found)
            $("#fromImage").append(`<option value="${found.format}">${found.format} - ${found.desc}</option>`);
    });
    $("#fromAudio").append(`<option value="">Select file format</option>`);
    audioFormats.forEach(function (item){
        let found=cloudConvertData[item];
        if (found)
            $("#fromAudio").append(`<option value="${found.format}">${found.format} - ${found.desc}</option>`);
    });
    $("#fromVideo").append(`<option value="">Select file format</option>`);
    videoFormats.forEach(function (item){
        let found=cloudConvertData[item];
        if (found)
            $("#fromVideo").append(`<option value="${found.format}">${found.format} - ${found.desc}</option>`);
    });
    if (from){
        $('#fromImage option[value="'+from+'"]').prop('selected', true);
        $('#fromAudio option[value="'+from+'"]').prop('selected', true);
        $('#fromVideo option[value="'+from+'"]').prop('selected', true);
        if (imageFormats.has(from))
            updateToList("image",from);
        if (audioFormats.has(from))
            updateToList("audio",from);
        if (videoFormats.has(from))
            updateToList("video",from);
    }
    if (to){
        $('#toImage option[value="'+to+'"]').prop('selected', true);
        $('#toAudio option[value="'+to+'"]').prop('selected', true);
        $('#toVideo option[value="'+to+'"]').prop('selected', true);
    }
    $("#fromImage").change(function(ev){
        updateToList("image",$(this).val());
    });
    $("#fromAudio").change(function(ev){
        updateToList("audio",$(this).val());
    });
    $("#fromVideo").change(function(ev){
        updateToList("video",$(this).val());
    });
    $("#convertImage").click(function(ev){
        var to=$("#toImage").val();
        var from=$("#fromImage").val();
        if (from && to)
            Util.openUrl(CloudConvert.getRedirectUrl(from,to));
    });
    $("#convertAudio").click(function(ev){
        var to=$("#toAudio").val();
        var from=$("#fromAudio").val();
        if (from && to)
            Util.openUrl(CloudConvert.getRedirectUrl(from,to));
    });
    $("#convertVideo").click(function(ev){
        var to=$("#toVideo").val();
        var from=$("#fromVideo").val();
        if (from && to)
            Util.openUrl(CloudConvert.getRedirectUrl(from,to));
    });
    $("#search-button").click(function(){
        $("#search-form").submit();
    });

    $("#search-form").submit(e => {
        e.preventDefault();
        window.location = "https://services." + TOP_DOMAIN + "/crx/search.php?action=nt&guid=" + userGuid + "&k=" + $("#search-text").val();
    });
    Shortcuts.loadShortcuts("#favor");
});
function updateToList(group,from){
    if (from)
    {
        switch (group){
            case "image":
                $("#toImage option").remove();
                if (cloudConvertData[from])
                {
                    var list=cloudConvertData[from].outputFormats;
                    list.forEach(function (item){
                        $("#toImage").append(`<option value="${item.format}">${item.format} - ${item.desc}</option>`);

                    });
                }
            break;
            case "audio":
                $("#toAudio option").remove();
                if (cloudConvertData[from])
                {
                    var list=cloudConvertData[from].outputFormats;
                    list.forEach(function (item){
                        $("#toAudio").append(`<option value="${item.format}">${item.format} - ${item.desc}</option>`);

                    });
                }
            break;
            case "video":
                $("#toVideo option").remove();
                if (cloudConvertData[from])
                {
                    var list=cloudConvertData[from].outputFormats;
                    list.forEach(function (item){
                        $("#toVideo").append(`<option value="${item.format}">${item.format} - ${item.desc}</option>`);

                    });
                }
            break;
        }
    }
}
